// wd-faq/assets/js/faq-main.js
// jQuery(document).ready(function(){
//     setTimeout(() => init(), 0);
//   });
//   function initFaq(){
//     console.log('Faq booted');
//   }


jQuery(document).ready(function () {

  jQuery('body').on('click', '.tablinks', function (e) {
    let category = jQuery(this).attr('data-category');
    setTimeout(() => { openFAQ(e, category); });
    setTimeout(() => { jQuery('#content-' + category).find('.wd-faq-card-wrapper:first-of-type').trigger('click'); });
  })

  jQuery('body').on('click', '.trigger-question', function (e) {
    let question = jQuery(this).attr('data-question');
    setTimeout(() => { openQuestion(e, question); });
  })

  setTimeout(() => {
    let tab1 = jQuery('.wd-faq-tabs-wrapper .tab:first-of-type .tablinks');
    tab1.trigger('click'); // Cliquer le premier tab
    setTimeout(() => tab1.addClass('active'), 0);
  });

})

function closeAllQuestions() {
  jQuery('.wd-faq-card-wrapper.opened').removeClass('opened').addClass('closed');
}


/**
* Open category tabs
* @param {*} event 
* @param {*} FAQCategory 
*/
function openFAQ(event, FAQCategory) {
  // Declare all variables
  var i, tabcontent, tablinks;

  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }

  // Show the current tab, and add an "active" class to the button
  document.getElementById('content-' + FAQCategory).style.display = "block";
  event.currentTarget.className += " active";
}

/**
* Toggle questions
* @param {*} event 
* @param {*} question 
*/

function openQuestion(event, question) {
  var q = document.getElementById('question-' + question);

  if (q.classList.contains("opened")) {
    // q.className = q.className.replace("opened", "closed");
  } else {
    closeAllQuestions();
    q.className = q.className.replace("closed", "opened");
  }
}


